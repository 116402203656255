//数据埋点
import request from '../utils/request'
import config from '../package.json'
import AES from './aes'
import * as sysTool from './systemTool'
import qs from 'qs'
function getcollect({ uid, gid, p, t, r, tl, v, pa, p0, d }) {
    let s = {
        //数据埋点数据整理
        cid: 'Server', //下载渠道
        uid: '', //登录用户id  h5不必传
        gid: gid,//必传 设备用户id：随机32位
        sv: sysTool.GetCurrentBrowser(),//系统版本号
        // TODO 需要获取package.json中version
        v: config.version, //客户端版本号
        did: localStorage.getItem('did'), //设备号
        pm: process.env.NODE_ENV != 'production' ? 0 : 1, //是否测试
        el: [{
            p: p ? p : window.location.href, //发起页面，见统一定义
            t: t, //触发事件类型，见统一定义
            r: r, //请求id，一般uuid生成，类型 string
            tl: tl, //二级事件类型分类，预留
            v: v, //事件值，预留
            ts: Date.now(), //事件产生时间
            e: {
                ip: sessionStorage.getItem('ip'),
                area: sessionStorage.getItem('area'),
                system: sysTool.GetOs()
            }, //额外参数，json格式，预留
            pa: pa, //发起事件页面区块，见统一定义
            p0: p0, //事件父页面
            d: 1, //页面深度
        }], //上报事件列表
    }
    console.log(s, '事件上报数据' + t)
    var params = AES.encrypt(JSON.stringify(s))
    console.log(params, '事件上报:' + t)
    let postparams = qs.stringify({ s: params })
    request.post(`/c`, postparams, { basurlType: "databurying", }).then(res => {

    })
}
export default getcollect;